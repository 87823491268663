<template>
  <div>
    <app-header />

    <div
      class="
        uk-section uk-padding-remove-vertical uk-margin-remove-vertical
        in-equity-breadcrumb
      "
    >
      <div class="uk-container">
        <div class="uk-grid">
          <div class="uk-width-1-1">
            <ul class="uk-breadcrumb">
              <li>
                <router-link :to="{ name: 'public.home' }">Home</router-link>
              </li>
              <li><span>Login</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div
      class="
        uk-section uk-section-muted uk-flex uk-flex-middle uk-animation-fade
      "
      style="padding-top; 0"
      uk-height-viewport
    >
      <div class="uk-width-1-1">
        <div class="uk-container">
          <div class="uk-grid-margin uk-grid uk-grid-stack" uk-grid>
            <div class="uk-width-1-1@m">
              <div
                class="
                  uk-margin
                  uk-width-2-3@m
                  uk-margin-auto
                  uk-card
                  uk-card-default
                  uk-card-body
                  uk-box-shadow-large
                "
              >
                <h3 class="uk-card-title uk-text-center">Welcome! Sign Up</h3>
                <div class="uk-alert-danger" uk-alert v-if="error != null">
                  <a class="uk-alert-close" uk-close href="#"></a>
                  <p v-html="error.message"></p>
                </div>
                <form @submit.prevent="submit">
                  <div class="uk-grid">
                    <div class="uk-width-1-1@m uk-margin-small">
                      <label for="">Name</label>
                      <div class="uk-inline uk-width-1-1">
                        <span class="uk-form-icon"
                          ><i class="fa fa-user" style="margin-right: 20px"></i
                        ></span>
                        <input
                          class="uk-input uk-form-large"
                          type="text"
                          required
                          v-model="user.name"
                          placeholder="Full Name"
                        />
                        <template v-if="error != null"
                          ><span
                            class="uk-text-danger"
                            v-if="error.errors.name"
                            >{{ error.errors.name[0] }}</span
                          ></template
                        >
                      </div>
                    </div>
                    <div class="uk-width-1-2@m uk-margin-small">
                      <label for="">Phone Number</label>
                      <div class="uk-inline uk-width-1-1">
                        <span class="uk-form-icon"
                          ><i class="fa fa-phone" style="margin-right: 20px"></i
                        ></span>
                        <input
                          type="text"
                          id="phone"
                          placeholder="Phone Number"
                          v-model="user.phone_number"
                          class="uk-input uk-form-large"
                        />
                        <template v-if="error != null"
                          ><span
                            class="uk-text-danger"
                            v-if="error.errors.phone_number"
                            >{{ error.errors.phone_number[0] }}</span
                          ></template
                        >
                      </div>
                    </div>
                    <div class="uk-width-1-2@m uk-margin-small">
                      <label for="">Email</label>
                      <div class="uk-inline uk-width-1-1">
                        <span class="uk-form-icon"
                          ><i
                            class="fa fa-envelope"
                            style="margin-right: 20px"
                          ></i
                        ></span>
                        <input
                          class="uk-input uk-form-large"
                          type="text"
                          required
                          v-model="user.email"
                          placeholder="Email"
                        />
                        <template v-if="error != null"
                          ><span
                            class="uk-text-danger"
                            v-if="error.errors.email"
                            >{{ error.errors.email[0] }}</span
                          ></template
                        >
                      </div>
                    </div>
                    <div class="uk-width-1-2@m uk-margin-small">
                      <label for="">Password</label>
                      <div class="uk-inline uk-width-1-1">
                        <span class="uk-form-icon"
                          ><i class="fa fa-lock" style="margin-right: 20px"></i
                        ></span>
                        <input
                          class="uk-input uk-form-large"
                          type="password"
                          required
                          v-model="user.password"
                          placeholder="Password"
                        />
                        <template v-if="error != null"
                          ><span
                            class="uk-text-danger"
                            v-if="error.errors.password"
                            >{{ error.errors.password[0] }}</span
                          ></template
                        >
                      </div>
                    </div>
                    <div class="uk-width-1-2@m uk-margin-small">
                      <label for="">Confirm Password</label>
                      <div class="uk-inline uk-width-1-1">
                        <span class="uk-form-icon"
                          ><i class="fa fa-lock" style="margin-right: 20px"></i
                        ></span>
                        <input
                          class="uk-input uk-form-large"
                          type="password"
                          required
                          v-model="user.password_confirmation"
                          placeholder="Confirm Password"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="uk-margin uk-text-center">
                    <button
                      class="
                        uk-button uk-button-primary uk-button-large uk-width-1-2
                      "
                    >
                      Sign Up
                    </button>
                  </div>
                  <div class="uk-text-small uk-text-center">
                    <router-link :to="{ name: 'login' }"
                      >Have an account? Login.</router-link
                    >
                  </div>
                  <div class="uk-text-small uk-text-center">
                    <router-link :to="{ name: 'forgot-password' }"
                      >Forgot your password?</router-link
                    >
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-footer />
  </div>
</template>

<script>
import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";
import intlTelInput from "intl-tel-input";

export default {
  components: {
    AppHeader,
    AppFooter,
  },

  mounted() {
    let input = document.querySelector("#phone");
    this.iti = intlTelInput(input, {
      preferredCountries: ["ke", "us"],
      separateDialCode: true,
    });
  },

  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      error: null,
      iti: "",
    };
  },

  methods: {
    submit() {
      this.$loader.start("full");
      this.error = null;
      this.user.phone_number = `${this.iti.getSelectedCountryData().dialCode}${
        this.user.phone_number
      }`;

      this.$axios
        .post("/api/v1/register", this.user)
        .then(() => {
          this.$router.push({ name: "check-email" });
          this.$loader.stop();
        })
        .catch((error) => {
          let response = error.response;
          this.$loader.stop();

          if (response.status == 422) {
            this.error = response.data;
          } else {
            this.error = {
              message: "An error occurred. Please try again later",
            };
          }
        });
    },
  },
};
</script>
